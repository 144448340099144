






































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';

const messages = namespace('Messages');

@Component({})
export default class ReplayAction extends Vue {
  @messages.State
  public nextBestActionEntries!: Array<NextBestActionEntry>;

  @messages.Action
  public triggerReplayAction!: (action: NextBestActionEntry) => void;

  @messages.Action
  public triggerRemoveReplayAction!: (action: NextBestActionEntry) => void;

  get nextBestActionEntriesReversed() {
    const currentState = [...this.nextBestActionEntries];
    return currentState.reverse();
  }

  outlined = true;
}
