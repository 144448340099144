











import { Component, Vue, Watch } from 'vue-property-decorator';
import Toolbar from '@/components/Toolbar.vue';
import ContentDrawer from '@/components/ContentDrawer.vue';
import { namespace } from 'vuex-class';
import ToolbarOption, { ToolbarOptionEntry } from '@/store/modules/ToolbarOption';
import Tester from '@/components/Tester.vue';
import twilioRealtimeSpeechService from '@/services/twilio-realtime-speech.service';
import { InteractConfig } from '@/types';
import { EventService } from '@/services/interact/EventService';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import DemoConfig from '@/store/modules/DemoConfig';
import { DemoConfigItem } from '@/services/demo-config.service';
import TwilioTelephony from '@/components/TwilioTelephony.vue';

const toolbarOption = namespace('ToolbarOption');
const transcription = namespace('Transcription');
const nextBestAction = namespace('NextBestAction');
const interactConfig = namespace('InteractChatConfig');
const InteractEventsNS = namespace('InteractEvents');
const messages = namespace('Messages');
const DemoConfigNS = namespace('DemoConfig');

@Component({
  components: {
    TwilioTelephony,
    Toolbar,
    ContentDrawer,
    Tester
  }
})
export default class Twilio extends Vue {
  private flexDirection = this.$route.params.side === 'right' ? 'row-reverse' : 'row';

  @DemoConfigNS.State
  demoConfigItem!: DemoConfigItem;

  @toolbarOption.Action
  private pushToolbarOption!: (toolbarOption: ToolbarOptionEntry | ToolbarOptionEntry[]) => void;

  @transcription.Action
  private subscribeToTwilioTranscript!: () => void;

  // used to be nextBestAction
  @messages.Action
  private subscribeToTwilioIntent!: () => void;

  @interactConfig.Action
  public updateConfig!: (config: InteractConfig) => void;

  @interactConfig.State
  config!: InteractConfig;

  @interactConfig.State
  botId!: string;

  @messages.Action
  public beginChat!: () => void;

  @InteractEventsNS.Action
  addEventService!: (config: {
    tenantId: string;
    apiKey: string;
    environment: 'Dev' | 'Test' | 'Prod';
  }) => Promise<string>;

  @InteractEventsNS.State
  eventService!: EventService;

  @messages.Action
  public addBotResponseOnly!: (botResponseText: string) => void;

  @messages.Action
  public triggerHiddenInput!: (botResponseText: string) => void;

  @messages.Action
  public triggerVisibleInput!: (botResponseText: string) => void;

  @messages.Action
  public addNextBestActionEntry!: (entry: NextBestActionEntry) => void;

  @DemoConfigNS.Action
  loadConfigById!: (configId: string) => void;

  @toolbarOption.State
  telephonyActive!: boolean;

  get getTelephonyActive(): boolean {
    return this.telephonyActive;
  }

  @Watch('botId')
  async onBotIdChanged(botId: string) {
    await this.registerAssistantEvents(botId);
  }

  private async registerAssistantEvents(botId: string) {
    if (!this.eventService) {
      console.log('no event service yet');
      setTimeout(async () => {
        await this.registerAssistantEvents(botId);
      }, 500);
    } else {
      console.log('subscribing to ' + botId + '/assistantEvents');
      this.eventService.subscribe('/' + botId + '/assistantEvents', (message) => {
        const parsedMessage = JSON.parse(message.body);
        console.log('Received event from assistantEvents', message.body);
        console.log('Received event from assistantEvents', parsedMessage);

        const eventData = parsedMessage.data as {
          eventType: 'trigger_hidden' | 'show_response_only' | 'trigger_visible' | 'next_best_action';
          text: string;
          name?: string;
          variableName?: string;
          variableValue?: string;
        };

        let stringToSend = eventData.text;

        switch (eventData.eventType) {
          case 'show_response_only':
            this.addBotResponseOnly(eventData.text);
            break;
          case 'trigger_hidden':
            if (eventData.variableName) {
              stringToSend += '##' + eventData.variableName + '###' + eventData.variableValue;
            }

            this.triggerHiddenInput(stringToSend);

            break;
          case 'trigger_visible':
            this.triggerVisibleInput(eventData.text);
            break;
          case 'next_best_action':
            this.addNextBestActionEntry({
              name: eventData.name,
              questionText: eventData.text
            });
            break;
          default:
            console.warn('Unknown event type received!', {
              parsedMessage: parsedMessage,
              eventData: eventData
            });
        }
      });
    }
  }

  async mounted() {
    // TODO - Maybe move this to the store, but then the two next toolbar options
    // would be added anyways, even if the functionality is not yet available.

    if (!this.demoConfigItem && this.$route.params.configId) {
      this.loadConfigById(this.$route.params.configId);
    }

    await this.addEventService(this.config);

    this.updateConfig((this.$route.query as unknown) as InteractConfig);

    const features = [
      {
        badge: 0,
        icon: 'fal fa-list',
        name: 'Knowledge',
        state: false,
        order: 4
      },
      {
        badge: 0,
        icon: 'fal fa-file-alt',
        name: 'Summary',
        state: false,
        order: 5
      },
      {
        badge: 0,
        icon: 'fal fa-id-card',
        name: 'Customer Info',
        state: false,
        order: 1
      },
      {
        badge: 0,
        icon: 'fal fa-rocket',
        name: 'Next Best Action',
        state: true,
        order: 0
      },
      {
        badge: 0,
        icon: 'fal fa-microphone',
        name: 'Transcript',
        state: false,
        order: 2
      },
      {
        badge: 0,
        icon: 'fal fa-bell',
        name: 'Notifications and Promises',
        state: false,
        order: 3
      }
    ];

    if (this.demoConfigItem) {
      const filteredFeatures = new Array<ToolbarOptionEntry>();

      features.map((value) => {
        if (this.demoConfigItem.features.find((value1) => value1.name === value.name)) {
          filteredFeatures.push(value);
        }
      });

      this.pushToolbarOption(filteredFeatures);

      const config: InteractConfig = {
        domainName:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_domain';
          })?.value || '',
        tenantId:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_tenant';
          })?.value || '',
        apiKey:
          this.demoConfigItem.configItems.find((item) => {
            return item.name === 'interact_app_key';
          })?.value || '',
        environment: 'Dev',
        mode: 'StandAlone'
      };
      if (config.domainName || config.tenantId || config.apiKey) {
        this.updateConfig(config);
      }
    } else {
      this.pushToolbarOption(features);
    }

    if (twilioRealtimeSpeechService.getSpeechEvents()) {
      await twilioRealtimeSpeechService.startListening(this.eventService, this.demoConfigItem);

      this.subscribeToTwilioIntent();

      this.subscribeToTwilioTranscript();
    } else {
      console.log('No speech events to listen for yet');
    }
  }
}
