





















































import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { Message } from '@/types';
import { namespace } from 'vuex-class';
import MessageBubble from '@/components/chat/MessageBubble.vue';
import UserInput from '@/components/chat/UserInput.vue';
import InteractChatConfig from '@/store/modules/InteractChatConfig';
import ReplayAction from '@/components/ReplayAction.vue';
import { EventService } from '@/services/interact/EventService';
import PreviousFlows from '@/components/PreviousFlows.vue';
import eventBus from '@/eventBus';

const messages = namespace('Messages');
const interactChatConfigNS = namespace('InteractChatConfig');

const InteractEventsNS = namespace('InteractEvents');

@Component({
  components: { PreviousFlows, ReplayAction, UserInput, MessageBubble }
})
export default class ChatView extends Vue {
  @messages.State
  public messages!: Array<Message>;

  @interactChatConfigNS.State
  public config!: InteractChatConfig;

  @InteractEventsNS.State
  eventServices!: Map<string, EventService>;

  private isAvailable = false;

  private showNotifications = false;

  mounted() {
    eventBus.$on('notificationsBarChanged', (showNotifications: boolean) => {
      this.showNotifications = showNotifications;
      if (this.showNotifications) {
        this.scrollToBottom();
      }
    });
  }
  scrollToBottom() {
    setTimeout(() => {
      try {
        const elem = document.getElementById('scrolled-content');
        const container = document.getElementById('scroll-target');
        if (elem && container) {
          container.scroll({
            behavior: 'smooth',
            top: Math.floor(elem.offsetHeight)
          });
        }
      } catch (e) {
        console.log('no elements yet');
      }
    }, 500);
  }

  @Watch('messages')
  messageAdded() {
    if (this.messages.length > 0) {
      this.isAvailable = true;
      this.scrollToBottom();
    }
  }
}
