




import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { TranscriptionMessageEntry } from '@/store/modules/Transcription';
import { NextBestActionEntry } from '@/store/modules/NextBestAction';
import { GuidanceEntry } from '@/store/modules/Guidance';
import moment from 'moment';
import { TriggerIntentFlow } from '@/services/interact/types/intent/intent-request';
import { SpeechEvent } from '@/services/types/speech';
import { NotificationObj } from "@/store/modules/Messages";
import eventBus from "@/eventBus";

const transcription = namespace('Transcription');
const nextbestaction = namespace('NextBestAction');
const guidance = namespace('Guidance');
const messages = namespace('Messages');

@Component({})
export default class Tester extends Vue {
  @transcription.Action
  private addMessage!: (message: TranscriptionMessageEntry) => void;

  @nextbestaction.Action
  private addNextBestAction!: (message: NextBestActionEntry) => void;

  @nextbestaction.Action
  private startCallAction!: () => void;

  @messages.Action
  private beginChat!: () => void;

  @guidance.Action
  private addAndStart!: (script: GuidanceEntry) => void;

  @messages.Action
  public addNextBestActionEntry!: (entry: NextBestActionEntry) => void;

  @messages.Action
  public triggerHiddenIntentFlow!: (triggerRequest: TriggerIntentFlow) => void;

  @messages.Action
  public handleAlertEvents!: (event: SpeechEvent) => void;

  @messages.Action
  private addNotification!: (notification: NotificationObj) => void;

  mounted() {
    setTimeout(() => {

      this.beginChat();


      /*

      this.sendCallminerStartEvent();
      setTimeout(() => {
        this.addNotification({
          message: 'Once you make the down payment, the policy is effective. Will email and mail proof of insurance.'
        });
      }, 5000);
      setTimeout(() => {
        eventBus.$emit("EntityAlert", { ShortText: 'Jake' });
      }, 6000);

      */

      // setTimeout(() => {
      //   this.handleAlertEvents({
      //     message: 'Once you make the down payment, the policy is effective. Will email and mail proof of insurance. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
      //     name: 'popup',
      //     sendAt: 0
      //   });
      // }, 8000);
    }, 1000);

    // setTimeout(() => {
    //   this.sendCallminerStartEvent();
    //
    //   setTimeout(() => {
    //     this.addNextBestActionEntry({
    //       name: 'Identify Product'
    //     });
    //
    //     this.triggerHiddenIntentFlow({
    //       flowId: '7d655fc3402b-bd3ecaee3d18c5ec-d71b',
    //       variables: {
    //         capturedCategory: 'Storage',
    //         capturedProductCategory: 'MSA',
    //         capturedProductDetails: '20 60 4'
    //       }
    //     });
    //
    //     setTimeout(() => {
    //       this.triggerHiddenIntentFlow({
    //         flowId: 'f805ca2faf39-1443406781090239-59ab',
    //         variables: {}
    //       });
    //
    //       setTimeout(() => {
    //         this.triggerHiddenIntentFlow({
    //           flowId: '504c713ca05f-8cb898da626cf115-8ae4',
    //           variables: {}
    //         });
    //       }, 500);
    //     }, 1000);
    //   }, 1000);
    // }, 1000);

    //this.sendNextBestActionMessages();
    //this.sendTranscriptionMessages();
    /*

    for (let i = 0; i < 30; i++) {
      this.addNextBestActionEntry({
        name: 'Test' + i,
        recognizedText: 'test'
      });
    }

    this.addAndStart({
      name: "Test Guidance",
      script: "http://test.de"
    });

 */
  }

  sendNextBestActionMessages() {
    // some text recognized from the voice provider + matched intent returned a script
    this.addNextBestAction({
      name: 'Recognized Intent',
      script: 'https://test.de',
      recognizedText: 'That´t what I understood'
    });

    // some text recognized from the voice provider + only an answer text from interact
    this.addNextBestAction({
      name: 'Another recognized intent',
      questionText: 'This is my question',
      answerText: 'Here is my answer with no script'
    });

    // some text recognized from the voice provider + answer text and script from interact
    this.addNextBestAction({
      name: 'Yet Another recognized intent',
      questionText: 'This is my question',
      answerText: 'Here is my answer with a script',
      script: 'https://test.de'
    });

    this.addNextBestAction({
      name: 'Yet Another recognized intent 1',
      questionText: 'This is my question',
      answerText: 'Here is my answer with a script',
      script: 'https://test.de'
    });
  }

  sendTranscriptionMessages() {
    const now = moment();

    this.addMessage({
      message: 'call you back maybe in the next week',
      from: 'agent',
      sentiment: 1,
      sendAt: now.add(10, 'seconds').unix()
    });
    /*
    this.addMessage({
      message: 'Test from customer',
      from: 'customer',
      sentiment: 0,
      sendAt: now.add(11, 'seconds').unix()
    });

     */
  }

  private sendCallminerStartEvent() {
    this.beginChat();
  }
}
