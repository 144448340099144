import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';
import store from '@/store/index';
import { IntentService } from '@/services/interact/intent.service';
import { CloudEnvironment, Environment } from '@/services/interact/types/shared/environment';
import { IntentHeaders } from '@/services/interact/types/intent/headers';
import { TriggerIntentFlow } from "@/services/interact/types/intent/intent-request";

export interface NextBestActionEntry {
  recognizedText?: string; // question text recognized from external system / speech
  questionText?: string; // question asked to intent flow from agent
  answerText?: string; // answer from intent flow
  name?: string; // button - intent name
  script?: string; // guidance script to start
  id?: number;
  flowEvent?: TriggerIntentFlow;
}

@Module({
  namespaced: true
})
export default class NextBestAction extends VuexModule {
  private messages: Array<NextBestActionEntry> = [];

  private interactService = new IntentService();
  private sessionId = '';

  private headers: IntentHeaders = {
    'Tenant-Id': 'quiq',
    'Application-Key': 'a9249024-979b-4974-bf6c-b7450b65b9f5',
    'Environment-Name': Environment.Dev
  };

  private cloud = CloudEnvironment.US;
  private domainName = 'DCMHomeAgentAssist';

  @Mutation
  pushMessage(message: NextBestActionEntry) {
    this.messages.unshift(message);
  }

  @Mutation
  reset() {
    debugger;
    this.messages = new Array<NextBestActionEntry>();
  }

  @Mutation
  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }

  @Action
  addNextBestAction(message: NextBestActionEntry) {
    if (message.id === undefined) {
      message.id = this.messages.length + 1;
    }

    this.context.commit('pushMessage', message);
  }

  @Action
  triggerNextBestAction(message: NextBestActionEntry) {
    console.log(message);
  }

  @Action
  async startCallAction() {
    console.log('Starting NBA listener');
    this.context.commit('reset');

    const botId = await this.interactService.getBotIdIfPossible(this.headers);

    const sessionStart = await this.interactService.createSession(
      { 'rpa-bot-id': botId, ...this.headers },
      {
        cloud: this.cloud,
        domainName: this.domainName
      }
    );

    if (sessionStart.greetingResponse) {
      await this.context.dispatch('addNextBestAction', {
        answerText: sessionStart.greetingResponse.text
      });
    }

    this.context.commit('setSessionId', sessionStart.sessionId);
  }

  @Action
  async customerQuestion(question: string) {
    console.log('Question', question);
    if (this.sessionId) {
      const response = await this.interactService.sendText({
        text: question,
        sessionId: this.sessionId
      });

      if (response) {
        let name = response.interactResponse.flowInformation.name;
        name = name.substr(name.indexOf('_') + 1, name.length);
        const messageToAdd: NextBestActionEntry = {
          name: name,
          questionText: name,
          answerText: name
        };
        console.log('message to add:', messageToAdd);
        await store.dispatch('Messages/addNextBestActionEntry', messageToAdd);
      }
    }

    // const answer = await this.interactService.transformHtml(response);
    //
    // const messageToAdd: NextBestActionEntry = {
    //   questionText: answer?.questionText,
    //   answerText: answer?.responseText
    // };
    //
    // if (answer?.buttons && answer.buttons.length > 0) {
    //   messageToAdd.name = answer.buttons[0].text;
    //   messageToAdd.script = answer.buttons[0].url;
    // }
    // console.log('message to add:', messageToAdd);
    // await this.context.dispatch('addNextBestAction', messageToAdd);
  }
}
