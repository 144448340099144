// store/modules/InteractChatConfig.ts
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { InteractConfig } from '@/types.ts';

type environment = 'Dev' | 'Test' | 'Prod';

@Module({ namespaced: true })
class InteractChatConfig extends VuexModule {
  public config: InteractConfig = {
    tenantId: 'nlpdemosdf',
    domainName: 'UAssistUITester',
    apiKey: '8f26279c-fe05-4269-8cc0-5103f57b08dc',
    environment: 'Dev',
    mode: 'StandAlone'
  };

  botId = '';

  @Mutation
  setBotId(botId: string) {
    this.botId = botId;
  }

  @Action
  async updateBotId(botId: string) {
    this.context.commit('setBotId', botId);
  }

  @Mutation
  public setChatSessionId(chatSessionId: string): void {
    const config = { ...this.config };
    config.chatSessionId = chatSessionId;
    this.config = config;
  }

  @Action
  public updateChatSessionId(chatSessionId: string): void {
    this.context.commit('setChatSessionId', chatSessionId);
  }

  @Mutation
  public setChatSessionUniqueId(chatSessionUniqueId: string): void {
    const config = { ...this.config };
    config.chatSessionUniqueId = chatSessionUniqueId;
    this.config = config;
  }

  @Action
  public updateChatSessionUniqueId(chatSessionUniqueId: string): void {
    this.context.commit('setChatSessionUniqueId', chatSessionUniqueId);
  }

  @Mutation
  public setApiKey(apiKey: string): void {
    this.config.apiKey = apiKey;
  }

  @Action
  public updateApiKey(apiKey: string): void {
    this.context.commit('setApiKey', apiKey);
  }

  @Mutation
  public setTenantId(tenantId: string): void {
    this.config.tenantId = tenantId;
  }

  @Action
  public updateTenantId(tenantId: string): void {
    this.context.commit('setTenantId', tenantId);
  }

  @Mutation
  public setDomainName(domainName: string): void {
    this.config.domainName = domainName;
  }

  @Action
  public updateDomainName(domainName: string): void {
    this.context.commit('setDomainName', domainName);
  }

  @Mutation
  public setEnvironment(environment: environment): void {
    this.config.environment = environment;
  }

  @Action
  public updateEnvironment(environment: environment): void {
    this.context.commit('setEnvironment', environment);
  }

  @Mutation
  public setConfig(config: InteractConfig): void {
    const tempThisConfig = this.config as any;
    const tempConfig = config as any;

    Object.keys(tempConfig).forEach((key) => {
      if (tempConfig[key] !== undefined && tempConfig[key] !== '') {
        tempThisConfig[key] = tempConfig[key];
      }
    });

    this.config = tempThisConfig;
  }

  @Action
  public updateConfig(config: InteractConfig): void {
    this.context.commit('setConfig', config);
  }

  public drawerOpen = false;

  @Mutation
  public setDrawerOpen(drawerOpen: boolean): void {
    this.drawerOpen = drawerOpen;
  }

  @Action
  public updateDrawerOpen(drawerOpen: boolean): void {
    this.context.commit('setDrawerOpen', drawerOpen);
  }

  public iframeSource = '';

  @Mutation
  public setIframeSource(iframeSource: string): void {
    this.iframeSource = iframeSource;
  }

  @Action
  public updateIframeSource(iframeSource: string): void {
    this.context.commit('setIframeSource', iframeSource);
  }

  @Action
  public resetDrawer(): void {
    this.context.commit('setIframeSource', '');
    this.context.commit('setDrawerOpen', false);
  }
}

export default InteractChatConfig;
