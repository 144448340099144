








import { Component, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class NotificationPromises extends Vue {

};
